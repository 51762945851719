<template>
  <div>
    <div class="form-group row">
      <legacy-phet-io-simulation
        :config="component.config"
        :variables="variableContext.variables"
      />
    </div>
    <template v-if="isAdmin || isContentDeveloper">
      <form-group class="row">
        <label :id="`${id}-version`" class="col-sm-2 control-label">
          PhET-iO Version
        </label>
        <div class="col-sm-10">
          <selector-input
            v-model="settings.version"
            :label="`${id}-version`"
            :aria-labelledby="`${id}-version`"
          >
            <selector-option value="1" title="1 (old)" />
            <selector-option value="2" title="2 (new)" />
          </selector-input>
        </div>
      </form-group>
      <form-group v-if="settings.version === '1'" class="row">
        <label class="col-sm-2 control-label" :for="`${id}-studio-html`">
          Studio HTML
        </label>
        <div class="col-md-6 col-sm-8">
          <multiline-text-input
            :id="`${id}-studio-html`"
            v-model="instanceProxiesHTML"
            help-text="Paste the HTML generated by the PhET-iO instance proxies studio"
            resizable-both
            label="studio html"
            @input="instanceProxiesHTMLChange"
          />
        </div>
      </form-group>
      <form-group v-if="settings.version === '2'" class="row">
        <label class="col-sm-2 control-label" :for="`${id}-file`">
          Studio HTML
        </label>
        <div class="col-md-6 col-sm-8">
          <input
            :id="`${id}-file`"
            type="file"
            label="Studio HTML"
            accept="text/html"
            @change="fileChange($event)"
          />
        </div>
      </form-group>
      <form-group class="row">
        <label class="col-md-2 col-sm-2 control-label" :for="`${id}-urls`">
          Script URLs
        </label>
        <div class="col-md-6 col-sm-8">
          <multiline-text-input
            :id="`${id}-urls`"
            v-model="settings.urls"
            help-text="Paste the URLs of the PhET-iO simulation javascripts, one per line"
            :rules="{
              required: true,
              urlPerLine: {
                require_protocol: true,
                protocols: ['https'],
                host_whitelist: ['phet-io.colorado.edu']
              }
            }"
            resizable-both
            label="URLs"
            :name="`${name}.settings.urls`"
          />
        </div>
      </form-group>
      <form-group class="row">
        <label class="col-md-2 col-sm-2 control-label" :for="`${id}-state`">
          State
        </label>
        <div class="col-md-6 col-sm-8">
          <multiline-text-input
            :id="`${id}-state`"
            v-model="settings.state"
            help-text="Paste the JSON state of the PhET-iO simulation"
            rules="json"
            resizable-both
            :name="`${name}.settings.state`"
            label="state"
            @blur="validateState"
          />
        </div>
      </form-group>
      <form-group class="row">
        <label class="col-sm-2 control-label" :for="`${id}-params`">
          Values
        </label>
        <div class="col-sm-10">
          <div v-if="settings.values.length > 0" class="value-row">
            <label class="control-label">Key</label>
            <label class="control-label">Value</label>
          </div>
          <div
            v-for="(value, index) in settings.values"
            :key="index"
            class="value-row"
          >
            <text-input
              v-model="settings.values[index].key"
              :name="`${name}.settings.values[${index}].key`"
              label="key"
              :rules="{ required: true }"
            />
            <text-input
              v-model="settings.values[index].value"
              :name="`${name}.settings.values[${index}].value`"
              label="value"
              :rules="{
                required: true,
                mathjs: {
                  variables: variableContext.variables
                }
              }"
            />
            <form-button secondary @click="removeValue(index)">
              <icon icon="close" />
              <span class="sr-only">Remove</span>
            </form-button>
          </div>
          <form-button secondary @click="addValue">
            <icon icon="plus" />
            <span class="sr-only">Add</span>Add Value</form-button
          >
        </div>
      </form-group>
      <div class="form-group row">
        <div class="col-md-6 col-sm-8">
          <form-button @click="onUpdate">Update Simulation</form-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LegacyPhetIoSimulation from 'src/shared/components/LegacyPhetIoSimulation'
import { replaceVariablesWithIds, replaceVariablesWithNames } from '@pi/shared/variables'

const htmlScriptRegEx = /src="([^"]+)\.js">/gi
const htmlJsonRegEx = /const state = \{(.+)\};/
const htmlJsonCommentsRegEx =
  /\/\*\*\*START_STATE\*\*\*\/\{(.+)\}\/\*\*\*END_STATE\*\*\*\//
const htmlExpressionsRegEx =
  /expressions = JSON\.parse\( decodeURIComponent\(([^)]+)\) \);/

let idCounter = 0

export default {
  name: 'EditLegacyPhetIoSimulation',
  inject: ['inherited'],
  components: { LegacyPhetIoSimulation },
  emits: ['change'],
  props: {
    component: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      settings: { version: '2', urls: '', state: '{}', values: [] },
      instanceProxiesHTML: ''
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isContentDeveloper']),
    id() {
      return `edit-legacy-phet-simulation-${idCounter++}`
    }
  },
  methods: {
    validateState() {
      if (this.settings.state === '') {
        this.settings.state = '{}'
      }
    },
    onUpdate() {
      try {
        // Convert variable names to ids
        const settings = {
          ...this.settings,
          values: this.settings.values
            .filter(({ key, value }) => !!key && !!value)
            .map(({ key, value }) => ({
              key,
              value: replaceVariablesWithIds(
                value,
                this.variableContext.variables
              )
            }))
        }
        const config = JSON.stringify(settings)
        this.$emit('change', { config })
      } catch {}
    },
    onFileRead(event) {
      try {
        const html = event.target.result.replace(/(\r\n|\n|\r)/gm, '')

        const srcMatch = [...html.matchAll(htmlScriptRegEx)]
        if (srcMatch && srcMatch.length > 0) {
          this.settings.urls = srcMatch
            .map(match => `${match[1]}.js`)
            .join('\n')
        }

        let jsonMatch = html.match(htmlJsonCommentsRegEx)
        if (!jsonMatch) {
          jsonMatch = html.match(htmlJsonRegEx)
        }

        if (jsonMatch && jsonMatch[1]) {
          try {
            const json = JSON.parse(`{${jsonMatch[1]}}`)
            this.settings.state = JSON.stringify(json, null, 3)
          } catch {
            this.$error('JSON state in HTML file is invalid.')
          }
        } else {
          this.settings.state = this.settings.state || '{}'
        }
      } catch {
        this.$error('Error reading HTML file.')
      }
    },
    fileChange(event) {
      try {
        this.settings.urls = ''
        this.settings.state = ''

        const reader = new FileReader()
        reader.onload = this.onFileRead
        reader.onerror = error => {
          throw error
        }
        reader.readAsText(event.target.files[0])
        event.target.value = null
      } catch {
        this.$error('Error reading HTML file.')
      }
    },
    instanceProxiesHTMLChange(value) {
      const html = value.replace(/(\r\n|\n|\r)/gm, '')

      const srcMatch = [...html.matchAll(htmlScriptRegEx)]
      if (srcMatch && srcMatch.length > 0) {
        this.settings.urls = srcMatch.map(match => `${match[1]}.js`).join('\n')
      }

      const expressionsMatch = html.match(htmlExpressionsRegEx)
      if (expressionsMatch && expressionsMatch[1]) {
        const encodedState = expressionsMatch[1].replace(/'/g, '')
        this.settings.state = decodeURIComponent(encodedState)
      }

      navigator.clipboard.writeText(html)

      this.instanceProxiesHTML = ''
    },
    addValue() {
      this.settings.values.push({ key: '', value: '' })
    },
    removeValue(index) {
      this.settings.values.splice(index, 1)
    }
  },
  watch: {
    component: {
      handler(value) {
        try {
          const settings = JSON.parse(value.config)
          if (Array.isArray(settings.values)) {
            settings.values = settings.values
              .filter(({ key, value }) => !!key && !!value)
              .map(({ key, value }) => ({
                key,
                value: replaceVariablesWithNames(
                  value,
                  this.variableContext.variables
                )
              }))
          } else {
            settings.values = []
          }
          this.settings = settings
        } catch {
          this.settings = { version: '2', urls: '', state: '{}', values: [] }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.value-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 5px;
}

.value-row:first-child {
  padding-bottom: 0px;
}

.value-row > span,
.value-row > label {
  flex: 1;
  text-align: left;
  padding-right: 5px;
}
</style>
