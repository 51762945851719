<template>
  <view-container>
    <view-header>
      <view-title> Assign Activity </view-title>
    </view-header>
    <async-form @submit="onSubmit">
      <form-group>
        <div class="class-name-subgroup">
          <form-label for="select-class">Classes</form-label>
          <autocomplete
            v-model="selectedClasses"
            :options="teacherClasses"
            :disabled="inLti"
            label-key="name"
            value-key="id"
            multiple
            placeholder="Choose classes"
            aria-label="chosen classes"
          />
        </div>
      </form-group>
      <NewAssignmentCard
        :key="activity.id"
        v-for="(activity, index) in activitiesToAssign"
        :name="activity.name"
        :dates="{
          startDate: activity.startDate,
          endDate: activity.endDate,
          firstVisibleDate: activity.firstVisibleDate,
          lastVisibleDate: activity.lastVisibleDate
        }"
        :hasIris="activity.categories.includes('iris')"
        :lastEntry="activitiesToAssign.length < 2"
        @update:dates="
          dates =>
            (activitiesToAssign[index] = {
              ...activitiesToAssign[index],
              ...dates
            })
        "
        @remove="removeActivity(index)"
      />
      <p v-if="selectedClasses.length > 1">
        These activities will be assigned to everybody in each selected class.
      </p>
      <div
        v-else-if="selectedClasses.length === 1"
        class="shadow-md border border-solid border-ui-gray-200 rounded-md p-6 my-10"
      >
        <form-group>
          <div class="font-bold text-black text-3xl mb-4">Assign To</div>
          <div class="class-name-subgroup">
            <selector-input
              v-model="assignedTo"
              aria-labelledby="assign-to"
              label="assign-to"
              class="selector-input--horizontal"
            >
              <selector-option value="everyone" title="Everybody" />
              <selector-option value="individuals" title="Individuals" />
              <selector-option value="groups" title="Co-Lab Groups" />
            </selector-input>
          </div>
        </form-group>
        <activity-subsets
          v-if="canAssignToIndividuals"
          v-model="students"
          :roster="selectedClassRoster"
        />
        <activity-groups
          v-if="canAssignGroups"
          :groups="groups"
          :roster="selectedClassRoster"
          @change="updateGroups"
        />
        <strong v-if="submitDisabled" class="text-danger error-message">
          You must have students enrolled in the class in order to create
          groups.
        </strong>
      </div>
      <AssignmentSettingsCard
        v-model="settings"
        class="mb-8"
        :activities="activitiesToAssign"
      />
      <form-group class="button-group">
        <submit-button
          class="pull-left"
          :disabled="submitDisabled || !selectedClasses.length"
        >
          <template #default>Assign</template>
          <template #submitting>Assigning</template>
        </submit-button>
        <form-button
          class="cancel-button"
          secondary
          @click.prevent="cancelAssignment"
        >
          Cancel
        </form-button>
      </form-group>
    </async-form>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
import ActivityGroups from '../components/ActivityGroups'
import ActivitySubsets from '../components/ActivitySubsets'
import NewAssignmentCard from '../components/NewAssignmentCard'
import Autocomplete from 'src/shared/components/Autocomplete'
import { postRedirect } from 'src/setup/mixins/form-post'
import AssignmentSettingsCard from '../components/AssignmentSettingsCard.vue'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'

export default {
  name: 'CreateAssignmentView',
  components: {
    ActivityGroups,
    ActivitySubsets,
    NewAssignmentCard,
    Autocomplete,
    AssignmentSettingsCard
  },
  data() {
    return {
      teacherClasses: [],
      selectedClass: {},
      selectedClasses: [],
      assignedTo: 'everyone',
      activitiesToAssign: [],
      groups: [],
      students: [],
      isLoading: false,
      ltiSession: {},
      settings: {
        noHints: false,
        isRandomized: true,
        studentFeedbackTiming:
          AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit,
        ignoreInstructorGradedPoints: false
      }
    }
  },
  props: {
    activityIds: {
      type: Array,
      required: true
    },
    classIds: {
      type: Array,
      default: () => []
    },
    inLti: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canAssignGroups() {
      return this.assignedTo === 'groups' && this.selectedClass?.roster?.length
    },
    canAssignToIndividuals() {
      return (
        this.assignedTo === 'individuals' && this.selectedClass?.roster?.length
      )
    },
    selectedClassRoster() {
      return (
        this.selectedClass.roster.map(s => {
          return {
            ...s.student,
            name: `${s.student.firstName} ${s.student.lastName}`
          }
        }) || []
      )
    },
    submitDisabled() {
      return this.assignedTo === 'groups' && !this.canAssignGroups
    }
  },
  async created() {
    this.isLoading = true

    await this.loadClasses()
    await this.loadActivities()
    this.isLoading = false
  },
  async mounted() {
    if (this.inLti) {
      try {
        this.ltiSession = await client.lti.getSession()

        if (
          this.ltiSession &&
          this.ltiSession.state &&
          this.ltiSession.state !== 'CONNECT_ASSIGNMENT' &&
          this.ltiSession.state !== 'CONNECTED'
        ) {
          await this.$router.push({ name: 'lti' })
        }
      } catch (_e) {}
    }
  },
  methods: {
    async onSubmit(e) {
      if (this.assignedTo === 'groups') {
        const emptyGroups = this.groups.filter(g => !g.students.length)
        if (emptyGroups.length) {
          emptyGroups.forEach(g => {
            this.$error(`A student must be assigned to ${g.name}`)
          })
          e.done(false)
          return
        }
      }
      if (this.assignedTo === 'individuals') {
        const isGroupEmpty = !this.students.length
        if (isGroupEmpty) {
          this.$error(`A student must be assigned.`)
          e.done(false)
          return
        }
      }
      if (this.inLti) {
        const response = await client.lti.addAssignments({
          classIds: this.selectedClasses,
          assignedTo: this.assignedTo,
          activityId: this.activitiesToAssign[0].id,
          startDate: this.activitiesToAssign[0].startDate,
          endDate: this.activitiesToAssign[0].endDate,
          firstVisibleDate: this.activitiesToAssign[0].firstVisibleDate,
          lastVisibleDate: this.activitiesToAssign[0].lastVisibleDate,
          students: this.students,
          groups: this.groups.map(g => {
            return {
              name: g.name,
              students: g.students.map(s => s._id)
            }
          }),
          ...this.settings
        })
        this.$success('Assignment connected successfully.')
        const { redirectUrl, token, openedInNewTab } = response
        if (openedInNewTab) {
          window.opener.postMessage(
            JSON.stringify({
              type: 'pivot:deep-link-response',
              redirectUrl,
              token
            }),
            `${window.location.protocol}//${window.PI_API_HOST}`
          )
          window.close()
        } else {
          postRedirect(redirectUrl, { JWT: token })
        }
      } else {
        const results = await Promise.allSettled(
          this.selectedClasses.map(async classId => {
            await client.classes.addAssignments({
              classId: classId,
              assignments: this.activitiesToAssign.map(activity => ({
                assignedTo: this.assignedTo,
                activityId: activity.id,
                startDate: activity.startDate,
                endDate: activity.endDate,
                firstVisibleDate: activity.firstVisibleDate,
                lastVisibleDate: activity.lastVisibleDate,
                students: this.students,
                groups: this.groups.map(g => {
                  return {
                    name: g.name,
                    students: g.students.map(s => s._id)
                  }
                }),
                ...this.settings
              }))
            })
          })
        )
        if (results.every(result => result.status === 'fulfilled')) {
          e.done()
          this.$success(`Assigned activities successfully.`)
          this.$router.push({
            name: 'classes'
          })
        } else {
          let failedToAssign = 0
          results.forEach((result, i) => {
            if (result.status !== 'fulfilled') {
              e.done(false)

              const errorActivityNames = result.reason.body.operations.map(
                op => this.activitiesToAssign[op.id].name
              )
              const nameOfFailingClass = this.teacherClasses.find(
                klass => klass.id === this.selectedClasses[i]
              ).name
              this.$error(
                `Failed to assign the following activities to the class ${nameOfFailingClass}: ${errorActivityNames.join(
                  ', '
                )}`
              )
              failedToAssign += errorActivityNames.length
            }
          })
          const totalToAssign =
            this.activitiesToAssign.length * this.selectedClasses.length
          this.$success(
            `Successfully assigned ${
              totalToAssign - failedToAssign
            } out of ${totalToAssign} total activities.`
          )
          this.$router.push({
            name: 'classes'
          })
        }
      }
    },
    cancelAssignment() {
      this.$router.push({ name: 'activities' })
    },
    async loadClasses() {
      this.selectedClasses = this.classIds
      const body = await client.classes.search({ limit: 1000 })
      const klasses = body.classes
      this.teacherClasses = klasses.filter(klass => klass.status === 'Active')
    },
    async loadActivities() {
      const activities = await Promise.all(
        this.activityIds.map(async activityId => {
          const start = new Date()
          const activity = await client.activities.get({
            activityId: activityId
          })
          return {
            ...activity,
            startDate: start,
            firstVisibleDate: start
          }
        })
      )
      this.activitiesToAssign = activities
    },
    initializeDates() {
      const start = new Date()
      this.startDate = start

      this.firstVisibleDate = start
    },
    updateGroups(groups) {
      this.groups = groups
    },
    removeActivity(index) {
      this.activitiesToAssign.splice(index, 1)
      const newQuery = {
        activityIds: this.activitiesToAssign.map(activity => activity.id)
      }
      this.$router.push({
        name: this.$route.name,
        query: newQuery
      })
    }
  },
  watch: {
    selectedClasses: {
      async handler(newClasses) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            classIds: newClasses
          }
        })
        this.selectedClass = {}
        if (newClasses.length === 1) {
          const body = await client.classes.get({ classId: newClasses[0] })
          this.selectedClass = body
        } else if (newClasses.length > 1) {
          this.assignedTo = 'everyone'
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: 600;
}

.class-name-subgroup {
  width: 370px;
}

.cancel-button {
  margin-left: 10px;
}

.date-group {
  display: flex;

  & > * {
    margin-right: 30px;
  }
}

.error-message {
  display: block;
  margin: 8px 16px 8px 0;
}
</style>
